import { $createImageNode } from '../nodes/ImageNode';
import { $createParagraphNode } from 'lexical';

export const appendImage = (selection, src) => {
  if (selection !== null) {
    const imageNode = $createImageNode(src, 0, 0, 'left');
    const paragraphNode = $createParagraphNode();
    selection.insertNodes([paragraphNode.append(imageNode)]);
  }
};
