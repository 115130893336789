import { memo, useContext, useEffect, useMemo, useState } from 'react';
import { isNumber } from 'lodash';

import type { ApiModel, GeneralModel } from '@cyferd/client-engine';
import { ViewModel, getDisabledStates, ofTypeSetData, swallowError, useUnmountObservable } from '@cyferd/client-engine';

import { CyWrapperContext } from '@components/smart/CyWrapper';
import { COLOR, GAP } from '@constants';
import { styles } from './styles';
import { ToolTip } from '@components/elements/Tooltip';
import { InfoForLabel } from '@components/elements/InfoForLabel';
import { CTA, CTAType } from '@components/elements/CTA';
import { Spinner } from '@components/elements/Spinner';
import { AssociationPending } from '../AssociationPending';
import type { IconKeys } from '@components/elements/Icon';
import { Icon } from '@components/elements/Icon';
import { takeLast, takeUntil, tap } from 'rxjs';
import { InputWrapper } from '@components/elements/InputWrapper';

const AssociationKpiCounter = ({ count, isGreyedOut }: { count: any; isGreyedOut: boolean }) =>
  !isNumber(count) ? (
    <div css={styles.spinnerContainer}>
      <Spinner />
    </div>
  ) : (
    <span css={[styles.counter, isGreyedOut && styles.disabled]}>
      : <span data-testid="kpi-value">{count}</span>
    </span>
  );

export interface AssociationKPIProps {
  disabled?: boolean;
  schema: GeneralModel.JSONSchema;
  errorMessage?: string;
  meta: ApiModel.ApiRecordAssociationMeta;
  recordMap: Record<string, ApiModel.ApiRecord>;
  disableCreateNew?: boolean;
  disableLink?: boolean;
  disableUnlink?: boolean;
  disabledType?: GeneralModel.DisabledType;
  onEdit: ViewModel.TriggerCallback<any>;
  onLink: (association: ApiModel.ApiAssociationChange, record: ApiModel.ApiRecord) => void;
  onUnlink: (association: ApiModel.ApiAssociationChange, record: ApiModel.ApiRecord) => void;
}

export const AssociationKPI = memo(
  ({
    disabled,
    schema,
    errorMessage,
    meta,
    recordMap,
    onEdit,
    onLink,
    onUnlink,
    disableCreateNew,
    disableLink,
    disableUnlink,
    disabledType
  }: AssociationKPIProps) => {
    const { useOnFetchEntity } = useContext(CyWrapperContext);
    const onDestroy$ = useUnmountObservable();
    const [associatedIcon, setAssociatedIcon] = useState<IconKeys>();
    const { isGreyedOut } = getDisabledStates(disabled, disabledType);
    const onFetchEntity = useOnFetchEntity();

    const count = useMemo(() => {
      if ([typeof meta?.totalCount, typeof meta?.add?.length, typeof meta?.remove?.length].every(type => type === 'number'))
        return meta.totalCount + meta.add.length - meta.remove.length;
    }, [meta?.add?.length, meta?.remove?.length, meta?.totalCount]);

    useEffect(() => {
      if (schema.metadata?.association?.cursor?.collectionId) {
        onFetchEntity(schema.metadata?.association?.cursor?.collectionId)
          .pipe(
            takeUntil(onDestroy$),
            ofTypeSetData(),
            takeLast(1),
            tap(({ query }) => setAssociatedIcon(query?.recordImage || /* istanbul ignore next */ '123')),
            swallowError()
          )
          .subscribe();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schema.metadata?.association?.cursor?.collectionId]);

    return (
      <InputWrapper
        disabled={!!disabled}
        disabledType={disabledType}
        errorMessage={errorMessage}
        description={schema.description}
        label=""
        unlimitedHeight={true}
      >
        <div css={styles.container} data-testid="association-kpi">
          <ToolTip text={`(${count}) ${schema.title}`}>
            <span css={[styles.title, isGreyedOut && styles.disabled]}>
              {!!associatedIcon && <Icon testid="associated-icon" name={associatedIcon} size={GAP.XL} fill={COLOR.NEUTRAL_1} />}
              <h3 css={[styles.titleText, isGreyedOut && styles.disabled]}>{schema.title}</h3>
              <AssociationKpiCounter count={count} isGreyedOut={isGreyedOut} />
              <InfoForLabel label={schema.title} value={schema?.info} />
            </span>
          </ToolTip>
          {(!disableLink || !disableUnlink || !disableCreateNew) && (
            <div css={styles.toolbar}>
              <div css={styles.toolbarItem}>
                <CTA
                  disabled={disabled}
                  icon="zoom_out_map"
                  tooltip="Open & Edit"
                  onClick={onEdit}
                  type={CTAType.LINK}
                  size={ViewModel.CTASize.LARGE}
                  testid="kpi-cta"
                />
              </div>
            </div>
          )}
        </div>
        {(!!meta?.add?.length || !!meta?.remove?.length) && (
          <div>
            <AssociationPending schema={schema} disabled={disabled} meta={meta} recordMap={recordMap} onCancelLink={onLink} onCancelUnlink={onUnlink} />
          </div>
        )}
      </InputWrapper>
    );
  }
);

AssociationKPI.displayName = 'AssociationKPI';
