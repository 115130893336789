import 'react-resizable/css/styles.css';
import { ResizableBox } from 'react-resizable';
import { forwardRef, useEffect, useState } from 'react';
import { useGetElementSize, useOutsideClick } from '@utils';
import { COLOR } from '@constants';
import { styles } from './styles';
import { $getNodeByKey } from 'lexical';
import { $isImageNode } from '../../nodes/ImageNode';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import type { AlignmentType } from '../../types';
import { Spinner } from '@components/elements/Spinner';

interface ImageBlockInterface {
  src: string;
  width: number;
  height: number;
  nodeKey: string;
  alignment: AlignmentType;
}

export const ImageBlock = ({ src, width, height, alignment, nodeKey }: ImageBlockInterface) => {
  const [editor] = useLexicalComposerContext();

  const isEditable = editor.isEditable();

  // TODO: use debounce?
  const [dimensions, setDimensions] = useState({ width, height });
  const [imageLoading, setImageLoading] = useState(true);
  const [isFocused, setIsFocused] = useState(false);

  const { ref: containerRef, width: containerWidth } = useGetElementSize();

  const ref = useOutsideClick(() => setIsFocused(false));

  const setSourceDimensions = (width, height) => {
    editor.update(() => {
      const node = $getNodeByKey(nodeKey);
      if ($isImageNode(node)) {
        node.setDimensions(width, height);
      }
    });
  };

  useEffect(() => {
    setSourceDimensions(dimensions.width, dimensions.height);
  }, [dimensions]);

  const handleImageLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
    const img = event.currentTarget;

    const naturalWidth = img.naturalWidth;
    const naturalHeight = img.naturalHeight;

    setDimensions(prev => {
      if (prev.width) return prev;

      const isTooBig = naturalWidth > containerWidth;

      if (isTooBig) {
        const calculatedHeight = (naturalHeight / naturalWidth) * containerWidth;

        return { width: containerWidth, height: calculatedHeight };
      }

      return { width: naturalWidth, height: naturalHeight };
    });

    setImageLoading(false);
  };

  const onResizeStop = (_event, { size }) => {
    const newWidth = size.width;

    setDimensions({ width: newWidth > containerWidth ? containerWidth : newWidth, height: size.height });
  };

  const toggleFocus = () => setIsFocused(prev => !prev);

  const getAlignmentProp = () => {
    if (alignment === 'left') return 'flex-start';
    if (alignment === 'right') return 'flex-end';

    return alignment;
  };

  const handleImageClick = () => {
    editor.update(() => {
      const node = $getNodeByKey(nodeKey);
      node.selectEnd();
    });
  };

  return (
    <div ref={containerRef} css={styles.container(getAlignmentProp())} onClick={toggleFocus}>
      <ResizableBox
        handle={<ResizeHandle />}
        width={typeof dimensions.width === 'string' ? parseInt(dimensions.width) : dimensions.width}
        height={typeof dimensions.height === 'string' ? parseInt(dimensions.height) : dimensions.height}
        axis="both"
        minConstraints={[50, 50]}
        maxConstraints={[containerWidth, Infinity]}
        onResizeStop={onResizeStop}
        resizeHandles={isFocused && isEditable ? ['sw', 'nw', 'se', 'ne'] : []}
        lockAspectRatio={true}
        style={{ border: isFocused && isEditable ? `2px solid ${COLOR.BE_7}` : 'none' }}
      >
        <img
          ref={ref}
          src={src}
          alt=""
          css={styles.image}
          style={{ display: imageLoading ? 'none' : 'block' }}
          onLoad={handleImageLoad}
          onClick={handleImageClick}
        />
      </ResizableBox>

      {imageLoading && (
        <div css={styles.spinnerContainer}>
          <Spinner />
        </div>
      )}
    </div>
  );
};

const ResizeHandle = forwardRef((props: any, ref) => {
  const { handleAxis, ...restProps } = props;

  return (
    <div
      ref={ref}
      css={[
        styles.handle,
        handleAxis === 'se' && styles.handleSE,
        handleAxis === 'sw' && styles.handleSW,
        handleAxis === 'ne' && styles.handleNE,
        handleAxis === 'nw' && styles.handleNW
      ]}
      {...restProps}
    />
  );
});
