import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';
import { $createParagraphNode, ParagraphNode } from 'lexical';
import { $isImageNode } from '../nodes/ImageNode';

export const ImageTransformPlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerNodeTransform(ParagraphNode, node => {
      const children = node.getChildren();
      const containsImage = children.some($isImageNode);

      if (containsImage && children.length > 1) {
        editor.update(() => {
          const newParagraph = $createParagraphNode();

          children.forEach(child => {
            if (!$isImageNode(child)) {
              child.remove();
              newParagraph.append(child);
            }
          });

          node.insertAfter(newParagraph);
          newParagraph.select();
        });
      }
    });
  }, [editor]);

  return null;
};
