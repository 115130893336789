import { css } from '@emotion/react';
import { COLOR } from '@constants';

export const styles = {
  handle: css`
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${COLOR.BE_7};
    cursor: grab;
  `,
  handleSE: css`
    bottom: -6px;
    right: -6px;
    cursor: nwse-resize;
  `,
  handleSW: css`
    bottom: -6px;
    left: -6px;
    cursor: nesw-resize;
  `,
  handleNE: css`
    top: -6px;
    right: -6px;
    cursor: nesw-resize;
  `,
  handleNW: css`
    top: -6px;
    left: -6px;
    cursor: nwse-resize;
  `,
  image: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
  `,
  spinnerContainer: css`
    position: absolute;
    left: 50%;
    top: 10px;
    transform: translate(-50%, -50%);
  `,
  container: alignment => css`
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: ${alignment};
  `
};
