/* istanbul ignore file */
/** leaving this here instead of client-app to use and test every component under client-app's conditions */

import type { PropsWithChildren } from 'react';
import { memo, useMemo } from 'react';
import { geocode } from 'opencage-api-client';

import { actionHooks, useFetchCollectionModel, useOnRefresh, useParsers } from '@cyferd/client-engine';

import { BaseForm } from '../CyForm/components/BaseForm';
import type { CyWrapperContextValue } from './CyWrapper';
import { CyWrapperContext } from './CyWrapper';
import { useNavigateToRecord } from '@components/elements/CollectionLookup/useNavigateToRecord';

const cyWrapperContextValue: CyWrapperContextValue = {
  useFetchCollectionModel,
  useOnRefresh: useOnRefresh,
  useParsers,
  useAction: actionHooks.useAction,
  useOnOpenEmailClient: actionHooks.useOnOpenEmailClient,
  useOnOpenExternalUrl: actionHooks.useOnOpenExternalUrl,
  useListAssociation: actionHooks.useListAssociation,
  useOnFetchEntity: actionHooks.useOnFetchEntity,
  useOnGetEntity: actionHooks.useOnGetEntity,
  useOnDownloadFile: actionHooks.useOnDownloadFile,
  useOnCreateAssociatedRecord: actionHooks.useOnCreateAssociatedRecord,
  useNavigateToRecord,
  renderSchemaForm: props => <BaseForm {...props} />,
  geocode
};

export const ClientAppCyWrapper = memo(({ children, override }: PropsWithChildren<{ override?: Partial<CyWrapperContextValue> }>) => {
  const value = useMemo(() => ({ ...cyWrapperContextValue, ...override }), [override]);
  return <CyWrapperContext.Provider value={value}>{children}</CyWrapperContext.Provider>;
});

ClientAppCyWrapper.displayName = 'ClientAppCyWrapper';
