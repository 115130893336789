import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $findMatchingParent, $getNearestBlockElementAncestorOrThrow, $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import {
  $createParagraphNode,
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  $isRootOrShadowRoot,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  INDENT_CONTENT_COMMAND,
  OUTDENT_CONTENT_COMMAND,
  SELECTION_CHANGE_COMMAND
} from 'lexical';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import type { HeadingTagType } from '@lexical/rich-text';
import { $createHeadingNode, $createQuoteNode, $isHeadingNode, $isQuoteNode } from '@lexical/rich-text';
import { $getSelectionStyleValueForProperty, $patchStyleText, $setBlocksType } from '@lexical/selection';
import { $isTableSelection } from '@lexical/table';
import { $createCodeNode } from '@lexical/code';
import { $isDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode';
import { ToolbarButton } from '../ToolbarButton';
import { VerticalDivider } from './components/VerticalDivider';
import { css } from '@emotion/react';
import { $isListNode, insertList, ListNode } from '@lexical/list';
import type { IconKeys } from '@components/elements/Icon';
import { Icon } from '@components/elements/Icon';
import { DropdownOption } from '@components/elements/DropdownOption';
import { styles } from './styles';
import { ColorPickerButton } from './components/ColorPickerButton';
import { BREAKPOINT_SIZE_L, BREAKPOINT_SIZE_M, HEADINGS_FONT_SIZE } from '../../constants';
import type { BlockType, ListType } from './types';
import { COLOR, TRANS } from '@constants';
import { $isExtendedTextNode } from '../../nodes/ExtendedTextNode';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { getSelectedNode } from '../../utils/positioning';
import { InsertOptionsSelector } from './components/InsertOptionsSelector';
import { ToolbarDropdown } from './components/ToolbarDropdown';
import { styleHelpers } from '@utils/styleHelpers';
import { formatInfoBlock } from './utils';
import { $isImageNode } from '../../nodes/ImageNode';
import type { AlignmentType } from '../../types';

const LowPriority = 1;

interface ToolbarProps {
  isCollapsed?: boolean;
}

export const Toolbar = ({ isCollapsed }: ToolbarProps) => {
  const [editor] = useLexicalComposerContext();

  const toolbarRef = useRef(null);
  const [viewportWidth, setViewportWidth] = useState(BREAKPOINT_SIZE_L);

  const [blockType, setBlockType] = useState<BlockType>('paragraph');

  const [alignmentType, setAlignmentType] = useState<AlignmentType>('left');
  const alignmentOption = alignmentOptions.find(option => option.value === alignmentType) || alignmentOptions[0];

  const [listType, setListType] = useState<ListType>(null);
  const listOption = listOptions.find(option => option.value === listType) || listOptions[0];

  const [isLink, setIsLink] = useState(false);

  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isSubscript, setIsSubscript] = useState(false);
  const [isSuperscript, setIsSuperscript] = useState(false);

  // TODO: implement contrast colors
  const [fontColor, setFontColor] = useState<string>('');
  const [bgColor, setBgColor] = useState<string>('');

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, e => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);

      const node = getSelectedNode(selection);
      const parent = node.getParent();

      const isParentLink = $isLinkNode(parent);
      const isNodeLink = $isLinkNode(node);

      setIsLink(isParentLink || isNodeLink);

      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode);

          const type = parentList ? parentList.getListType() : element.getListType();

          setListType(type);
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();

          setListType(null);

          if (type in blockTypeToBlockName) {
            setBlockType(type as BlockType);
          }
        }
      }

      setFontColor($getSelectionStyleValueForProperty(selection, 'color', ''));
      setBgColor($getSelectionStyleValueForProperty(selection, 'background-color', ''));

      setAlignmentType(($isElementNode(node) ? node.getFormatType() : parent?.getFormatType() || 'left') as AlignmentType);

      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));
      setIsSuperscript(selection.hasFormat('superscript'));
      setIsSubscript(selection.hasFormat('subscript'));
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, _newEditor) => {
          $updateToolbar();
          return false;
        },
        LowPriority
      )
    );
  }, [editor, $updateToolbar]);

  useLayoutEffect(() => {
    if (toolbarRef?.current) {
      setViewportWidth(toolbarRef.current.offsetWidth);
    }
  }, []);

  const getOptionLabel = (type: BlockType, label: string) => {
    switch (type) {
      case 'h1':
        return (
          <h1
            css={css`
              font-size: ${HEADINGS_FONT_SIZE.h1};
              color: inherit;
            `}
          >
            {label}
          </h1>
        );
      case 'h2':
        return (
          <h2
            css={css`
              font-size: ${HEADINGS_FONT_SIZE.h2};
              color: inherit;
            `}
          >
            {label}
          </h2>
        );
      case 'h3':
        return (
          <h3
            css={css`
              font-size: ${HEADINGS_FONT_SIZE.h3};
              color: inherit;
            `}
          >
            {label}
          </h3>
        );
      case 'h4':
        return (
          <h4
            css={css`
              font-size: ${HEADINGS_FONT_SIZE.h4};
              color: inherit;
            `}
          >
            {label}
          </h4>
        );
      default:
        return label;
    }
  };

  const onFormatSelect = (value: BlockType) => {
    if (value === 'paragraph') {
      formatParagraph();
    } else if (value === 'code') {
      formatCode();
    } else if (value === 'quote') {
      formatQuote();
    } else {
      formatHeading(value);
    }
  };

  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode());
      }
    });
  };

  const formatHeading = (headingSize: HeadingTagType) => {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection();
        $setBlocksType(selection, () => $createHeadingNode(headingSize));
      });
    }
  };

  const clearFormatting = useCallback(() => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection) || $isTableSelection(selection)) {
        const anchor = selection.anchor;
        const focus = selection.focus;
        const nodes = selection.getNodes();
        const extractedNodes = selection.extract();

        if (anchor.key === focus.key && anchor.offset === focus.offset) {
          return;
        }

        nodes.forEach((node, idx) => {
          if ($isExtendedTextNode(node)) {
            let textNode = node;
            if (idx === 0 && anchor.offset !== 0) {
              textNode = textNode.splitText(anchor.offset)[1] || textNode;
            }
            if (idx === nodes.length - 1) {
              textNode = textNode.splitText(focus.offset)[0] || textNode;
            }

            const extractedTextNode = extractedNodes[0];
            if (nodes.length === 1 && $isExtendedTextNode(extractedTextNode)) {
              textNode = extractedTextNode;
            }

            if (textNode.__style !== '') {
              textNode.setStyle('');
            }
            if (textNode.__format !== 0) {
              textNode.setFormat(0);
              $getNearestBlockElementAncestorOrThrow(textNode).setFormat('');
            }
          } else if ($isHeadingNode(node) || $isQuoteNode(node)) {
            node.replace($createParagraphNode(), true);
          } else if ($isDecoratorBlockNode(node)) {
            node.setFormat('');
          }
        });
      }
    });
  }, [editor]);

  const formatCode = () => {
    if (blockType !== 'code') {
      editor.update(() => {
        let selection = $getSelection();

        if (selection !== null) {
          if (selection.isCollapsed()) {
            $setBlocksType(selection, () => $createCodeNode());
          } else {
            const textContent = selection.getTextContent();
            const codeNode = $createCodeNode();
            selection.insertNodes([codeNode]);
            selection = $getSelection();
            if ($isRangeSelection(selection)) {
              selection.insertRawText(textContent);
            }
          }
        }
      });
    } else {
      formatParagraph();
    }
  };

  const insertLink = useCallback(() => {
    const url = isLink ? null : 'https://';

    editor.dispatchCommand(TOGGLE_LINK_COMMAND, url);
  }, [editor, isLink]);

  const formatQuote = () => {
    if (blockType !== 'quote') {
      editor.update(() => {
        const selection = $getSelection();
        $setBlocksType(selection, () => $createQuoteNode());
      });
    } else {
      formatParagraph();
    }
  };

  const onListFormatSelect = (value: ListType) => {
    if (value === 'bullet') {
      formatBulletList();
    } else if (value === 'number') {
      formatNumberedList();
    } else if (value === 'check') {
      formatCheckList();
    }
  };

  const formatBulletList = () => {
    if (listType !== 'bullet') {
      insertList(editor, 'bullet');
    } else {
      formatParagraph();
    }
  };

  const formatNumberedList = () => {
    if (listType !== 'number') {
      insertList(editor, 'number');
    } else {
      formatParagraph();
    }
  };

  const formatCheckList = () => {
    if (listType !== 'check') {
      insertList(editor, 'check');
    } else {
      formatParagraph();
    }
  };

  const applyStyleText = (styles: Record<string, string>) => {
    editor.update(() => {
      const selection = $getSelection();
      if (selection !== null) {
        $patchStyleText(selection, styles);
      }
    });
  };

  const onFontColorSelect = (value: string) => {
    applyStyleText({ color: value });
  };

  const onBgColorSelect = (value: string) => {
    applyStyleText({ 'background-color': value });
  };

  const onAlignmentSelect = (value: AlignmentType) => {
    editor.update(() => {
      const selection = $getSelection();
      if (!$isRangeSelection(selection)) return;

      const nodes = selection.getNodes();

      nodes.forEach(node => {
        editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, value);

        if ($isImageNode(node)) {
          node.setAlignment(value);
        }
      });
    });
  };

  const toolbarStyles = useMemo(() => styles.toolbar(isCollapsed, viewportWidth), [isCollapsed, viewportWidth]);

  return (
    <div css={toolbarStyles} ref={toolbarRef}>
      <ToolbarDropdown
        trigger={
          <div css={styles.blockOptionDropdown(viewportWidth)}>
            <div css={styleHelpers.flexStart()}>
              <Icon name={blockTypeToBlockName[blockType].icon as IconKeys} size="24px" outlined={blockType !== 'quote'} />
              {viewportWidth >= BREAKPOINT_SIZE_L && <p>{blockTypeToBlockName[blockType].label}</p>}
            </div>

            <Icon name="arrow_drop_down" />
          </div>
        }
        options={Object.entries(blockTypeToBlockName).map(([key, value]) => (
          <DropdownOption
            key={key}
            image={value.icon}
            title={getOptionLabel(key as BlockType, value.label) as string}
            value={key}
            onClick={onFormatSelect}
            imageOutlined={key !== 'quote'}
            active={key === blockType}
          />
        ))}
      />

      <VerticalDivider />

      <ToolbarButton
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
        }}
        iconName="format_bold"
        tooltip={TRANS.RichTextEditor.formatBold}
        active={isBold}
      />

      <ToolbarButton
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
        }}
        iconName="format_italic"
        tooltip={TRANS.RichTextEditor.formatItalic}
        active={isItalic}
      />

      <ToolbarButton
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
        }}
        iconName="format_underlined"
        tooltip={TRANS.RichTextEditor.formatUnderline}
        active={isUnderline}
      />

      <ToolbarButton
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
        }}
        iconName="strikethrough_s"
        tooltip={TRANS.RichTextEditor.formatStrikethrough}
        active={isStrikethrough}
      />

      <ToolbarDropdown
        trigger={
          <>
            <Icon name="FormatIcon" size="24px" />
            <Icon name="arrow_drop_down" />
          </>
        }
        options={formattingOptions.map(option => (
          <DropdownOption
            key={option.value}
            image={option.icon}
            title={option.label}
            value={option.value}
            onClick={value => (value === 'clear' ? clearFormatting() : editor.dispatchCommand(FORMAT_TEXT_COMMAND, value))}
            active={(isSuperscript && option.value === 'superscript') || (isSubscript && option.value === 'subscript')}
          />
        ))}
      />

      <VerticalDivider />

      <ToolbarDropdown
        trigger={
          <>
            <Icon name={listOption.icon as IconKeys} size="24px" outlined={true} />
            <Icon name="arrow_drop_down" />
          </>
        }
        options={listOptions.map(option => (
          <DropdownOption
            key={option.value}
            image={option.icon}
            title={option.label}
            value={option.value}
            onClick={onListFormatSelect}
            imageOutlined={true}
            active={listType === option.value}
          />
        ))}
      />

      <VerticalDivider />

      <ToolbarDropdown
        trigger={
          <>
            <Icon name={alignmentOption.icon as IconKeys} size="24px" outlined={true} />
            <Icon name="arrow_drop_down" />
          </>
        }
        options={
          <>
            {alignmentOptions.map(option => (
              <DropdownOption
                key={option.value}
                image={option.icon}
                title={option.label}
                value={option.value}
                onClick={onAlignmentSelect}
                imageOutlined={true}
                active={alignmentType === option.value}
              />
            ))}

            <hr css={styles.hr} />

            <DropdownOption
              key="outdent"
              image="format_indent_decrease"
              title="Outdent"
              value="outdent"
              onClick={() => editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined)}
              imageOutlined={true}
            />
            <DropdownOption
              key="indent"
              image="format_indent_increase"
              title="Indent"
              value="indent"
              onClick={() => editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined)}
              imageOutlined={true}
            />
          </>
        }
      />

      <VerticalDivider />

      <ColorPickerButton color={fontColor} onColorChange={onFontColorSelect} iconName="format_color_text" tooltip={TRANS.RichTextEditor.formatTextColor} />

      <ColorPickerButton color={bgColor} onColorChange={onBgColorSelect} iconName="border_color" tooltip={TRANS.RichTextEditor.formatTextBgColor} />

      <VerticalDivider />

      <ToolbarButton onClick={insertLink} iconName="link" tooltip={TRANS.RichTextEditor.formatLink} active={isLink} />

      {viewportWidth >= BREAKPOINT_SIZE_M && (
        <ToolbarDropdown
          trigger={
            <>
              <Icon name="info" size="24px" outlined={true} />
              <Icon name="arrow_drop_down" />
            </>
          }
          options={infoBlockOptions.map(option => (
            <DropdownOption
              key={option.value}
              image={option.icon}
              title={option.label}
              value={option.value}
              onClick={value => formatInfoBlock(editor, value)}
              imageFill={option.fill}
              imageOutlined={true}
            />
          ))}
        />
      )}

      <VerticalDivider />

      <InsertOptionsSelector isSmallViewport={viewportWidth <= BREAKPOINT_SIZE_M} />

      <VerticalDivider />
    </div>
  );
};

const blockTypeToBlockName = {
  h1: {
    icon: 'format_h1',
    label: 'Heading 1'
  },
  h2: {
    icon: 'format_h2',
    label: 'Heading 2'
  },
  h3: {
    icon: 'format_h3',
    label: 'Heading 3'
  },
  h4: {
    icon: 'format_h4',
    label: 'Heading 4'
  },
  paragraph: {
    icon: 'format_paragraph',
    label: 'Paragraph'
  },
  code: {
    icon: 'code',
    label: 'Code block'
  },
  quote: {
    icon: 'format_quote',
    label: 'Quote'
  }
};

const formattingOptions = [
  { value: 'subscript', label: TRANS.RichTextEditor.formatSubscript, icon: 'subscript' },
  { value: 'superscript', label: TRANS.RichTextEditor.formatSuperscript, icon: 'superscript' },
  { value: 'clear', label: TRANS.RichTextEditor.clearFormatting, icon: 'format_clear' }
];

const infoBlockOptions = [
  { value: 'info', label: TRANS.RichTextEditor.infoBlock, icon: 'info', fill: COLOR.BE_2 },
  { value: 'success', label: TRANS.RichTextEditor.successBLock, icon: 'check_circle', fill: COLOR.GN_3 },
  { value: 'warning', label: TRANS.RichTextEditor.warningBLock, icon: 'warning', fill: COLOR.OE_3 },
  { value: 'alert', label: TRANS.RichTextEditor.alertBlock, icon: 'error', fill: COLOR.RD_3 }
];

// TODO: implement check list
const listOptions = [
  { value: 'bullet', label: TRANS.RichTextEditor.formatBullet, icon: 'format_list_bulleted' },
  { value: 'number', label: TRANS.RichTextEditor.formatNumber, icon: 'format_list_numbered' },
  { value: 'check', label: TRANS.RichTextEditor.formatCheck, icon: 'checklist' }
];

const alignmentOptions = [
  { value: 'left', label: TRANS.RichTextEditor.alignLeft, icon: 'format_align_left' },
  { value: 'center', label: TRANS.RichTextEditor.alignCenter, icon: 'format_align_center' },
  { value: 'right', label: TRANS.RichTextEditor.alignRight, icon: 'format_align_right' },
  { value: 'justify', label: TRANS.RichTextEditor.alignJustify, icon: 'format_align_justify' }
];
