import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { DRAG_DROP_PASTE } from '@lexical/rich-text';
import { isMimeType, mediaFileReader } from '@lexical/utils';
import { $getSelection, COMMAND_PRIORITY_LOW } from 'lexical';
import { useContext, useEffect } from 'react';
import { uiActions } from '../../../../../client-app/state-mgmt/ui';
import { ClientEngineContext, createUUID, tapOnSuccess, useUnmountObservable } from '@cyferd/client-engine';
import { ToastStatus } from '@components/elements/Toast';
import { catchError, EMPTY, takeUntil } from 'rxjs';
import { logger } from '@utils';
import type { FileObject } from '../types';
import { useDispatch } from 'react-redux';
import { useFileManagement } from '../hooks/useFileManagement';
import { appendImage } from '../utils/image';

const ACCEPTABLE_IMAGE_TYPES = ['image/', 'image/gif', 'image/webp'];

export const DragDropPastePlugin = () => {
  const [editor] = useLexicalComposerContext();

  const dispatch = useDispatch();
  const { onUploadFile } = useFileManagement();
  const onDestroy$ = useUnmountObservable();
  const { platformConfig } = useContext(ClientEngineContext);

  const uploadImage = (file: File) => {
    onUploadFile(file)
      .pipe(
        takeUntil(onDestroy$),
        tapOnSuccess(apiFileData => {
          dispatch(uiActions.addToast({ id: createUUID(), status: ToastStatus.SUCCESS, title: `The image "${file.name}" was uploaded successfully.` }));
          logger.debug('Success uploading image', apiFileData);

          editor.update(() => {
            const selection = $getSelection();
            appendImage(selection, `${platformConfig?.fileUploadUrl}/${(apiFileData as FileObject)?.id}`);
          });
        }),
        catchError(error => {
          dispatch(
            uiActions.addToast({ id: createUUID(), status: ToastStatus.ERROR, title: `Something wrong happened. The image "${file.name}" cannot be uploaded.` })
          );

          logger.error(`Error uploading image "${file.name}"`, { error });
          return EMPTY;
        })
      )
      .subscribe();
  };

  useEffect(() => {
    return editor.registerCommand(
      DRAG_DROP_PASTE,
      files => {
        (async () => {
          const filesResult = await mediaFileReader(files, ACCEPTABLE_IMAGE_TYPES);

          if (filesResult.length) {
            dispatch(uiActions.addToast({ id: createUUID(), status: ToastStatus.INFO, title: 'The image upload was initiated. Please wait.' }));
          }

          for (const { file } of filesResult) {
            if (isMimeType(file, ACCEPTABLE_IMAGE_TYPES)) {
              uploadImage(file);
            }
            // TODO: DnD file upload
          }
        })();
        return true;
      },
      COMMAND_PRIORITY_LOW
    );
  }, [editor]);

  return null;
};
