import React, { memo, useCallback, useContext, useEffect, useState } from 'react';

import type { ApiModel, GeneralModel, ViewModel } from '@cyferd/client-engine';
import { tapOnSuccess, useOnRefresh } from '@cyferd/client-engine';

import { CyWrapperContext } from '../../../../smart/CyWrapper';
import type { CollectionLookupProps } from '../../../CollectionLookup';
import { CollectionLookup } from '../../../CollectionLookup';
import { CTA, CTAType } from '../../../CTA';
import { styles } from './styles';
import { TRANS } from '@constants';

export interface AssociationDropdownProps {
  value: ApiModel.ApiRecordAssociationList[];
  // TODO: fix type on client-engine
  meta: ApiModel.ApiRecordAssociationMeta;
  disabled?: boolean;
  autoFocus?: boolean;
  shouldPassRecord?: boolean;
  disableCreateNew?: boolean;
  disableLink?: boolean;
  disableUnlink?: boolean;
  schema: GeneralModel.JSONSchema;
  delay?: number;
  doSet?: boolean;
  doClear?: boolean;
  componentName: string;
  fixedFilter?: GeneralModel.FetchCriteria['fixedFilter'];
  apiQuery: ApiModel.ApiValue['query'];
  disabledType?: GeneralModel.DisabledType;
  onChange: (event: Partial<ApiModel.ApiRecordAssociationMeta>, record?: ApiModel.ApiRecord) => void;
  onAdd: ViewModel.TriggerCallback<any>;
}
export const AssociationDropdown = memo(
  ({
    autoFocus,
    disabled,
    disabledType,
    disableCreateNew,
    disableLink,
    shouldPassRecord,
    disableUnlink,
    schema,
    delay,
    value,
    meta,
    componentName,
    apiQuery,
    fixedFilter,
    onChange,
    onAdd,
    doSet,
    doClear
  }: AssociationDropdownProps) => {
    const { useListAssociation } = useContext(CyWrapperContext);
    const selectedId: string = value?.[0]?.id;
    const [state, setState] = useState<string>(selectedId);

    /** START this won't be needed after the new creation flow is implemented */
    const onListAssociated = useListAssociation(apiQuery, schema);
    /* istanbul ignore next line */
    const onFetch = useCallback(
      (fetchCriteria: GeneralModel.FetchCriteria) => onListAssociated(fetchCriteria).pipe(tapOnSuccess(v => setState(v?.list?.[0]?.id))),
      [onListAssociated]
    );
    useOnRefresh({ componentName }, onFetch);
    /** END this won't be needed after the new creation flow is implemented */

    const innerOnChange = useCallback((value, record) => (shouldPassRecord ? onChange(value, record) : onChange(value)), [onChange, shouldPassRecord]);

    const onInternalChange = useCallback(
      (event: string, record?: ApiModel.ApiRecord) => {
        setState(event);
        if (event === selectedId) {
          innerOnChange({ add: [], remove: [], modify: [] }, record);
          return;
        }
        innerOnChange(
          {
            [doSet ? 'set' : 'add']: !!event ? [{ id: event }] : [],
            ...(doClear
              ? { clear: !!event ? false : true }
              : { remove: value?.map(i => !!i?.$r?.id && { id: i?.id, $r: { id: i?.$r?.id } })?.filter(Boolean) }),
            modify: []
          },
          record
        );
      },
      [selectedId, innerOnChange, doSet, doClear, value]
    );

    const onInternalAdd = useCallback(
      (onCloseDropdown: () => void) => {
        onInternalChange('');
        onAdd();
        onCloseDropdown();
      },
      [onAdd, onInternalChange]
    );

    const renderCustomOption: CollectionLookupProps['renderCustomOption'] = useCallback(
      ({ onClose }) =>
        !!onAdd && (
          <div css={styles.createNewOption} data-testid="create-new" onClick={() => onInternalAdd(onClose)}>
            <CTA label={TRANS.client.buttons.createNew} icon="add" type={CTAType.LINK} />
          </div>
        ),
      [onAdd, onInternalAdd]
    );

    useEffect(() => {
      setState(selectedId);
    }, [selectedId]);

    useEffect(() => {
      if (meta?.add?.[0]?.id) setState(meta?.add?.[0]?.id);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <CollectionLookup
        autoFocus={autoFocus}
        testid="association-dropdown"
        value={state}
        disableFreeText={true}
        label={schema?.title}
        description={schema?.description}
        collectionId={schema?.metadata?.association?.cursor?.collectionId}
        disabled={disabled}
        avoidFetch={disableLink}
        delay={delay}
        onChange={onInternalChange}
        showClear={!disableUnlink}
        fixedFilter={fixedFilter}
        renderCustomOption={!disableCreateNew && renderCustomOption}
        info={schema?.info}
        disabledType={disabledType}
      />
    );
  }
);

AssociationDropdown.displayName = 'AssociationDropdown';
