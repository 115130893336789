import { memo, useCallback, useContext, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { EMPTY, map, takeUntil } from 'rxjs';

import type { ApiModel } from '@cyferd/client-engine';
import { GeneralModel, ViewModel, actions, getDisabledStates, isObject, swallowError, tapOnSuccess, useUnmountObservable } from '@cyferd/client-engine';

import { CyWrapperContext } from '../../../../smart/CyWrapper';
import type { CyListContextValue } from '../../../../smart/CyList';
import { CyList, CyListContext } from '../../../../smart/CyList';
import { CyLayout } from '../../../../smart/CyLayout';
import { AssociationAdder } from '../AssociationAdder';
import { styles } from './styles';
import { CTA, CTAType } from '../../../CTA';
import { AssociationPending } from '../AssociationPending';
import { getAssociationBoundaries } from '@utils';
import { TRANS } from '@constants';
import { InputWrapper } from '@components/elements/InputWrapper';

const associationListTypeMap = {
  [GeneralModel.JSONSchemaSubtype.ASSOCIATION_LIST]: ViewModel.CyListType.LIST,
  [GeneralModel.JSONSchemaSubtype.ASSOCIATION_TABLE]: ViewModel.CyListType.TABLE,
  [GeneralModel.JSONSchemaSubtype.ASSOCIATION_CARD]: ViewModel.CyListType.CARD,
  [GeneralModel.JSONSchemaSubtype.ASSOCIATION_GRID]: ViewModel.CyListType.GRID
};

export interface AssociationListBaseProps {
  disabled?: boolean;
  schema: GeneralModel.JSONSchema;
  apiQuery: ApiModel.ApiValue['query'];
  initialValue?: ApiModel.ApiValue;
  componentName?: string;
  subtype: GeneralModel.JSONSchemaSubtype;
  defaultFilter: GeneralModel.FetchCriteria['filter'];
  fixedFilter: GeneralModel.FetchCriteria['fixedFilter'];
  meta: ApiModel.ApiRecordAssociationMeta;
  recordMap: Record<string, ApiModel.ApiRecord>;
  testid?: string;
  disableCreateNew?: boolean;
  disableLink?: boolean;
  disableUnlink?: boolean;
  errorMessage?: string;
  disabledType?: GeneralModel.DisabledType;
  onAdd: ViewModel.TriggerCallback<any>;
  onClickItem: ViewModel.CyListProps['onClickItem'];
  onOpenLinked: ViewModel.TriggerCallback<any>;
  onOpenUnlinked: ViewModel.TriggerCallback<any>;
  onLink: (association: ApiModel.ApiAssociationChange, record: ApiModel.ApiRecord) => void;
  onUnlink: (association: ApiModel.ApiAssociationChange, record: ApiModel.ApiRecord) => void;
}
export const AssociationListBase = memo(
  ({
    subtype,
    disabled,
    disableCreateNew,
    disableLink,
    disableUnlink,
    schema,
    apiQuery,
    initialValue,
    componentName,
    defaultFilter,
    fixedFilter,
    meta,
    recordMap,
    testid = 'association-list-base',
    errorMessage,
    disabledType,
    onClickItem,
    onAdd,
    onOpenLinked,
    onOpenUnlinked,
    onLink,
    onUnlink
  }: AssociationListBaseProps) => {
    const { useListAssociation } = useContext(CyWrapperContext);
    const [state, setState] = useState<ApiModel.ApiValue>(initialValue);
    const onListAssociated = useListAssociation(apiQuery, schema);
    const title = schema?.title;
    const controlsRef = useRef<HTMLDivElement>();
    const [controlsContainer, setControlsContainer] = useState<HTMLDivElement>();
    const onDestroy$ = useUnmountObservable();

    const { isReadonly, isGreyedOut } = getDisabledStates(disabled, disabledType);

    const internalTotalCount = useMemo(() => meta?.totalCount ?? 0, [meta?.totalCount]);
    const { canLink, canUnlink } = getAssociationBoundaries(schema, meta);

    const cyListContextValue: CyListContextValue = useMemo(
      () => ({ controlsPortalRef: controlsContainer, controlCTASize: ViewModel.CTASize.LARGE }),
      [controlsContainer]
    );

    const listType = associationListTypeMap[subtype] || ViewModel.CyListType.LIST;

    const initialFetchCriteria = useMemo(
      () =>
        isObject(schema?.metadata?.association?.cursor) &&
        ({ ...schema.metadata.association.cursor, options: { limit: 5, ...schema.metadata.association.cursor.options } } as GeneralModel.FetchCriteria),
      [schema?.metadata?.association?.cursor]
    );

    const onFetch = useCallback(
      (fetchCriteria: GeneralModel.FetchCriteria) => onListAssociated(fetchCriteria).pipe(takeUntil(onDestroy$), swallowError()),
      [onDestroy$, onListAssociated]
    );

    const onFetchCyList = useCallback(
      (fetchCriteria: GeneralModel.FetchCriteria) =>
        onFetch(fetchCriteria).pipe(
          tapOnSuccess(val => setState(val)),
          map(v => actions.dispatchSetData({ value: v }))
        ),
      [onFetch]
    );

    useLayoutEffect(() => {
      setControlsContainer(controlsRef.current);
    }, []);

    const actionListChildren = useMemo(
      () =>
        disableUnlink || isReadonly
          ? []
          : [
              event => {
                const acc = meta?.remove?.find(a => a?.id === event?.item?.id);
                const isLink = [ViewModel.CyListType.LIST, ViewModel.CyListType.TABLE].includes(listType);
                const helperText = acc ? 'Undo' : 'Remove';
                return {
                  helperText,
                  label: isLink ? undefined : helperText,
                  icon: (acc ? 'undo' : 'close') as GeneralModel.IconName,
                  type: CTAType.LINK,
                  important: true,
                  disabled: disabled || (acc ? !canLink : !canUnlink),
                  onClick: () => {
                    onUnlink(acc || { id: event?.item?.id }, event?.item);
                    return EMPTY;
                  }
                };
              }
            ],
      [canLink, canUnlink, disableUnlink, disabled, isReadonly, listType, meta?.remove, onUnlink]
    );

    return (
      <div css={styles.main}>
        <InputWrapper
          isContainerInput={true}
          id={testid}
          testid={testid}
          isCollapsible={!!internalTotalCount}
          startsCollapsed={schema?.metadata?.startsCollapsed}
          label={title}
          count={internalTotalCount}
          description={!isReadonly && schema?.description}
          info={schema?.info}
          errorMessage={errorMessage}
          disabled={isGreyedOut}
          unframed={false}
          optionMenu={
            <div>
              <div css={styles.toolbar}>
                <div data-testid={`${testid}-controls-portal-ref`} css={styles.controlsContainer} ref={controlsRef} />
                <div>
                  {!!internalTotalCount && (
                    <CTA
                      type={CTAType.LINK}
                      icon="zoom_out_map"
                      disabled={disabled}
                      tooltip="Open & Update"
                      onClick={onOpenLinked}
                      testid={`${testid}-modal-toggle`}
                      size={ViewModel.CTASize.LARGE}
                    />
                  )}
                </div>
              </div>
            </div>
          }
        >
          <div>
            <div css={styles.listHeader}>
              {!disableLink && !isReadonly && (
                <AssociationAdder
                  meta={meta}
                  collectionId={schema?.metadata?.association?.cursor?.collectionId}
                  apiQuery={apiQuery}
                  associationKey={schema?.metadata?.association?.cursor?.associationKey}
                  defaultFilter={defaultFilter}
                  fixedFilter={fixedFilter}
                  disabled={disabled || !canLink}
                  onAdvSearch={onOpenUnlinked}
                  onNew={!disableCreateNew && onAdd}
                  onLink={onLink}
                />
              )}
              {disableLink && !disableCreateNew && !isReadonly && (
                <CTA
                  type={CTAType.TERTIARY}
                  testid="create-new-btn"
                  size={ViewModel.CTASize.SMALL}
                  icon="add"
                  label={TRANS.client.buttons.createNew}
                  onClick={onAdd}
                  disabled={disabled}
                />
              )}
              <AssociationPending schema={schema} disabled={disabled} meta={meta} recordMap={recordMap} onCancelLink={onLink} onCancelUnlink={onUnlink} />
            </div>
            <CyLayout itemMaxHeight="400px">
              <CyListContext.Provider value={cyListContextValue}>
                <div>
                  <CyList
                    framed={false}
                    componentName={componentName}
                    initialFetchCriteria={initialFetchCriteria}
                    onFetch={onFetchCyList}
                    onClickItem={onClickItem}
                    value={state}
                    type={listType}
                    typeSelectorHidden={true}
                    actionListChildren={actionListChildren}
                  >
                    <div />
                  </CyList>
                </div>
              </CyListContext.Provider>
            </CyLayout>
          </div>
        </InputWrapper>
      </div>
    );
  }
);

AssociationListBase.displayName = 'AssociationListBase';
