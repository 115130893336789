import type { LexicalNode, NodeKey, DOMConversionMap } from 'lexical';
import { DecoratorNode } from 'lexical';
import { ImageBlock } from '../components/Image';
import type { AlignmentType } from '../types';

const imageClassIdentifier = 'image-block';

export class ImageNode extends DecoratorNode<JSX.Element> {
  src: string;
  alignment: AlignmentType = 'left';
  width: number;
  height: number;

  constructor(src, width, height, alignment, key?: NodeKey) {
    super(key);
    this.src = src;
    this.width = width;
    this.height = height;
    this.alignment = alignment;
  }

  static getType() {
    return 'image';
  }

  static clone(node) {
    return new ImageNode(node.src, node.width, node.height, node.alignment, node.__key);
  }

  createDOM(): HTMLElement {
    const element = document.createElement('span');
    element.className = imageClassIdentifier;

    return element;
  }

  updateDOM() {
    return false;
  }

  static importJSON(serializedNode) {
    return new ImageNode(serializedNode.src, serializedNode.width, serializedNode.height, serializedNode.alignment);
  }

  exportJSON() {
    return {
      version: 1,
      type: 'image',
      src: this.src,
      width: this.width,
      height: this.height
    };
  }

  exportDOM() {
    const element = document.createElement('img');

    element.classList.add(imageClassIdentifier);
    element.src = this.src;
    element.setAttribute('data-identifier', imageClassIdentifier);
    element.setAttribute('data-width', this.width?.toString());
    element.setAttribute('data-height', this.height?.toString());
    element.setAttribute('data-alignment', this.alignment);

    return { element };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      img: (domNode: HTMLElement) => {
        if (!domNode.classList.contains(imageClassIdentifier) && domNode.getAttribute('data-identifier') !== imageClassIdentifier) return null;

        return {
          conversion: $parseImageElement,
          priority: 2
        };
      }
    };
  }

  setSource(src: string) {
    const writable = this.getWritable();

    writable.src = src;
  }

  setDimensions(width, height) {
    const writable = this.getWritable();

    writable.width = width;
    writable.height = height;
  }

  setAlignment(alignment) {
    const writable = this.getWritable();

    writable.alignment = alignment;
  }

  getAlignment() {
    return this.alignment;
  }

  decorate() {
    return <ImageBlock src={this.src} width={this.width} height={this.height} alignment={this.alignment} nodeKey={this.getKey()} />;
  }
}

const $parseImageElement = domNode => {
  const src = domNode.getAttribute('src');
  const width = domNode.getAttribute('data-width');
  const height = domNode.getAttribute('data-height');
  const alignment = domNode.getAttribute('data-alignment');

  const node = $createImageNode(src, width, height, alignment);

  return { node };
};

export const $createImageNode = (src, width, height, alignment) => {
  return new ImageNode(src, width, height, alignment);
};

export const $isImageNode = (node: LexicalNode | null | undefined): node is ImageNode => {
  return node instanceof ImageNode;
};
